import type { TabletopData } from '~/components/tabletop/tabletopUtil'

export default () => {
  const state = useStore()

  const table = computed<TabletopData>(() => state.table)
  const sendCommand = inject<(cmd: string, data?: any) => any>('sendCommand', (_cmd: string) => {})

  return { table, sendCommand }
}
