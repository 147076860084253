<template>
  <div :class="['character', ...char.conditions].join(' ')" draggable="true" :style="creatureStyle">
    <Condition :conditions="char.conditions" :size="char.size" />
    <div v-if="char.aura > 0" class="aura" :style="auraStyle"></div>
    <div class="name">{{ char.name }}{{ number }}</div>
  </div>
</template>

<script setup lang="ts">
import { clamp } from '../util'
import { type CharData, contains } from './tabletopUtil'
import Condition from './Condition.vue'

const props = defineProps<{
  char: CharData
  dungeonMaster: boolean
}>()

const { table } = useTabletop()

const isVisible = computed(() => {
  if (props.dungeonMaster) return true

  return (
    Object.values(table.value.map.fog)
      .filter((fog) => fog.rect.type === 'darkness')
      .findIndex((fog) => contains(fog.rect, props.char.position)) === -1
  )
})

const creatureStyle = computed(() => ({
  display: isVisible.value ? 'flex' : 'none',
  '--size': props.char.size,
  background: props.char.sprite || '#f0f',
  ...style.value,
}))

const auraStyle = computed(() => ({
  '--aura-radius': props.char.aura,
}))

const style = computed(() => {
  if (!table.value.map.dimensions) return
  const x = clamp(props.char.position.x, 0, table.value.map.dimensions.x)
  const y = clamp(props.char.position.y, 0, table.value.map.dimensions.y)
  return {
    'grid-column-start': x,
    'grid-column-end': `span ${props.char.size}`,
    'grid-row-start': y,
    'grid-row-end': `span ${props.char.size}`,
  }
})

const number = computed(() => {
  if (!props.char.number) return '' // for newCreature
  const chars = Object.values(table.value.characters)
  const sameName = chars.filter((c) => c.name === props.char.name)
  if (sameName.length < 2) return ''
  return ` ${props.char.number}`
})
</script>

<style scoped lang="postcss">
.character {
  border: 2px solid black;
  border-radius: 50%;

  background: #6495ed;
  background-size: cover !important;
  box-shadow: 0 0 8px black;
  user-select: none;

  z-index: 200;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 1s, opacity 0.5s;

  &.editing {
    border: 2px solid white;
  }

  .name {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    font-family: var(--font-stack-title);
    text-transform: capitalize;
    font-size: calc(0.75em + (1em * var(--size) / 4));
    color: white;
    text-shadow: 0 0 2px black, 0 0 1px black;
    text-align: center;
    z-index: 250;
    pointer-events: none;
    white-space: pre;
  }

  .aura {
    --aura-radius: 1;
    position: absolute;
    pointer-events: none;
    width: calc(var(--grid-size) * (var(--size) + 2 * var(--aura-radius)));
    height: calc(var(--grid-size) * (var(--size) + 2 * var(--aura-radius)));
    top: calc(-1 * var(--grid-size) * var(--aura-radius));
    left: calc(-1 * var(--grid-size) * var(--aura-radius));
    box-shadow: 0 0 30px #fffc inset;
    border: 1px solid #fffc;
    border-radius: 50%;
    z-index: 50;
  }

  &.prone {
    transform: rotate(90deg);
    transform-origin: center;
  }

  &.invisible {
    opacity: 0.5;
  }

  &.petrified {
    filter: saturate(0.33);
  }
}
</style>
