// this file and estia-server/src/model/model.ts should be kept in sync

export const GRID_SIZE = 60

export type Position = {
  x: number
  y: number
}

export type Area = Position & {
  w: number
  h: number
}

export type CharData = {
  id: string
  name: string
  number?: number
  sprite: string
  position: Position
  size: number | string
  aura: number
  friendly: boolean
  initiative?: number
  initMod?: number
  conditions: string[]
}

export type PlayerData = {
  id: string
  name: string
  color: string
  pointers: Area[]
  dm: boolean
}

export type FogData = {
  id: string
  rect: Area & { type: string }
}

export type MapData = {
  sprite: string
  style: Record<string, string>
  dimensions: Position
  fog: Record<string, FogData>
}

export type TabletopData = {
  characters: Record<string, CharData>
  players: Record<string, PlayerData>
  map: MapData
}

export const contains = ({ x, y, w, h }: Area, { x: px, y: py }: Position) =>
  x <= px && px < x + w && y <= py && py < y + h

export const createArea = (pos1: Position, pos2: Position): Area => ({
  x: Math.min(pos1.x, pos2.x),
  y: Math.min(pos1.y, pos2.y),
  w: Math.abs(pos1.x - pos2.x) + 1,
  h: Math.abs(pos1.y - pos2.y) + 1,
})
